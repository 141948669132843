import React from 'react'

export const StoreButton = ({ link, store }) => {
  let btnSrc;
  switch (store) {
    case "steam":
      btnSrc = "/btn_steam.png";
      break;
    case "psStore":
      btnSrc = "/btn_ps.png";
      break;
    case "macAppStore":
      btnSrc = "/btn_mac.png";
      break;
    case "msStore":
      btnSrc = "/btn_ms.png";
      break;
    case "epicStore":
      btnSrc = "/btn_epic.png";
      break;
    case "nintendoStore":
      btnSrc = "/btn_nintendo.png";
      break;
  }
  return (
    <a href={link} className="store-btn">
      <img src={btnSrc} />
    </a>
  );
}
