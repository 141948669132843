import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import { StoreButton } from '../components/store-button'
import ParallaxMousemove from 'react-parallax-mousemove'
import Slider from "react-slick";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import specialFormatter from '../utility/special-formatter'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false
    };
  }

  componentDidMount() {
    document.body.classList.add('body-title');

    this.setState({
      mounted: true
    });
  }

  componentWillUnmount() {
    document.body.classList.remove('body-title');
  }

  youtubeId(url) {
    const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match&&match[1].length==11)? match[1] : false;
  }

  render() {
    const game = get(this.props, 'data.contentfulGame')
    const gameTitleImages = get(this.props, 'data.contentfulGame.titleImage');
    const blogPost = get(this, 'props.data.allContentfulBlogPost.edges[0].node');
    const blurbs = get(this, 'props.data.allContentfulPressBlurbs.edges');
    const videos = get(this, 'props.data.allContentfulYoutube.edges');
    const storeItems = get(this, 'props.data.allContentfulStoreItems.edges');
    const sliderSettings = {
    infinite: true,
    arrows: false,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 3,
      responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
    };

    const style ={
      outer: {
        background: `url(${game.titleBackground.fixed.src})`,
        backgroundSize: 'cover',
        position: 'relative',
        height: 500,
        margin: 'auto'
      },
      inner: {
        position: 'absolute',
        height: '100%',
      },
      image: {
        zIndex: 3,
        height: 500,
        width: '100%',
      }
    }

    let slider = null;

    if (this.state.mounted) {
      slider = (
        <Slider {...sliderSettings} className="home-store-items">
          {storeItems.map(({ node }) => {
            return (
              <div key={node.name}>
                <a href={node.url}>
                  <img src={node.imageUrl} />
                  <span>{node.name}</span>
                </a>
              </div>
            )
          })}
        </Slider>
      )
    }

    let pressKit = null;

    if (game.pressKit) {
      pressKit = (
        <p className="wy-text-center">
          Want a quick to-go bag of {game.name} media, wallpapers, and logos?
          <a href={game.pressKit.file.url}> Download this handy zip file.</a>
        </p>
      )
    }

    let esrb = null;

    if (game.esrbRating) {
      esrb = (
        <li>
          <a href={game.esrbRating.fixed.src}>
            <Img fluid={game.esrbRating.fluid} />
          </a>
        </li>
      )
    }

    return (
      <Layout location={this.props.location} >

        <div className='parallax-container'>
          <ParallaxMousemove containerStyle={style.outer}>
            <div className="header-bg" />
            <div style={{ maxWidth: 1200, margin: 'auto', position: 'relative' }}>
              {gameTitleImages.map((node, idx) => {
                return (
                  <ParallaxMousemove.Layer key={'parallax' + idx} layerStyle={style.inner} config={{
                      xFactor: node.description,
                      yFactor: 0,
                    }}>
                    <img src={node.fixed.src} alt="Parallax Layer" style={style.image} />
                  </ParallaxMousemove.Layer>
                );
              })}
              <ParallaxMousemove.Layer layerStyle={style.inner} config={{
                  xFactor: 0,
                  yFactor: 0,
                }}>
                <img src={game.logo.fixed.src} alt="Parallax Layer" style={style.image} />
              </ParallaxMousemove.Layer>
            </div>
          </ParallaxMousemove>
        </div>
        <div className="game-news">
          <div className="container">
            <strong><span className="fas fa-star"></span> From the Supergiant blog: </strong>
            <Link to={`/blog/${blogPost.slug}`}>{blogPost.title}</Link>
          </div>
        </div>


        <div className="game-info">
          <div className="container">
            <div className="half game-info-text">
              <Img fixed={game.boxArt.fixed} className="img" />
              <h2>About the game</h2>
              <p>{ReactHtmlParser(specialFormatter(game.about.childContentfulRichText.html, true))}</p>
              {(game.faq !== null) ?
                <p><Link to={`/blog/${game.faq.slug}`}><span className="fas fa-book"></span> Read the full FAQ</Link></p>
              : null
              }
              <hr />
              <h3>Available on</h3>
              <span class="store-buttons">
              {(game.steam !== null) ? <StoreButton link={game.steam} store="steam" />: null}
              {(game.macAppStore !== null) ? <StoreButton link={game.macAppStore} store="macAppStore" />: null}
              {(game.msStore !== null) ? <StoreButton link={game.msStore} store="msStore" />: null}
              {(game.epicStore !== null) ? <StoreButton link={game.epicStore} store="epicStore" />: null}
              {(game.nintendoStore !== null) ? <StoreButton link={game.nintendoStore} store="nintendoStore" />: null}
              {(game.psStore !== null) ? <StoreButton link={game.psStore} store="psStore" />: null}
              </span>
              {(game.steamEmbed !== null && game.steamEmbed !== '-') ? <iframe className="steam-embed" src={ game.steamEmbed }  width="646" height="190" frameborder="0" /> : null}
            </div>
            <div className="half game-info-reviews">
              {(blurbs !== undefined) ? <h2>What people are saying</h2> : null}
              {(blurbs !== undefined) ? blurbs.map(({ node }, idx) => {
                return (
                  <div key={'blurb' + idx}>
                    <a href={node.url}>
                      <span className="excerpt">{node.excerpt}</span>
                      <span className="score">
                        {node.publication}
                        {(node.score !== null) ? ` - ${node.score}` : null}
                      </span>
                    </a>
                  </div>
                )
              }) :
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: specialFormatter(game.prereleaseNote.childContentfulRichText.html, true),
                    }}
                  />
                </div>
              }
            </div>
          </div>
        </div>
        <div className="media">
          { pressKit }
          <ul>
            {videos.map(({ node }, idx) => {
              return (
                <li key={'video' + idx}>

                  <a
                    href={node.url}
                    className="media-video fab fa-youtube"
                    style={{backgroundImage: `url(https://i.ytimg.com/vi/${this.youtubeId(node.url)}/hqdefault.jpg)`}}
                   >
                    <span className="media-video-name">{node.title}</span>
                  </a>
                </li>
              )
            })}
            {game.screenshots.map((node, idx) => {
              return (
                <li key={'screenshot' + idx}>
                  <a href={node.fixed.src}>
                    <Img fluid={node.fluid} />
                  </a>
                </li>
              );
            })}
            {esrb}
          </ul>
        </div>

        <div className="store" style={{ backgroundColor: game.color }}>
          <div className="store-line"></div>
          <div className="container">
            <div className="store-album">
              {(game.bandCampEmbed !== null) ?
                <div
                  dangerouslySetInnerHTML={{
                    __html: game.bandCampEmbed.bandCampEmbed
                  }}
                />
                : null
              }
            </div>
            <div className="store-items">
              <h2>Shop at our Online Store</h2>
              <p>International shipping available.<br/>We fulfill all online orders straight from our San Francisco studio.</p>
              <hr />
              <div className="slide-store">
                {slider}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query GamePageBySlug($slug: String!) {
    contentfulGame(slug: { eq: $slug }) {
      name
      publishDate(formatString: "MMMM Do, YYYY")
      bandCampEmbed {
        bandCampEmbed
      }
      slug
      color
      steam
      psStore
      macAppStore
      msStore
      epicStore
      nintendoStore
      steamEmbed
      pressKit {
        file {
          url
          fileName
          contentType
        }
      }
      faq {
        slug
      }
      about {
        childContentfulRichText {
          html
        }
      }
      prereleaseNote {
        childContentfulRichText {
          html
        }
      }
      epicStore
      boxArt {
        fixed(width: 225, quality: 100) {
          ...GatsbyContentfulFixed
        }
      }
      titleBackground {
        fixed(width: 1200, height: 500, quality: 80, resizingBehavior: FILL) {
          ...GatsbyContentfulFixed
        }
      }
      logo {
        fixed(width: 1200, height: 500, quality: 80, resizingBehavior: FILL) {
          ...GatsbyContentfulFixed
        }
      }
      titleImage {
        description
        fixed(width: 1200, height: 500, quality: 80, resizingBehavior: FILL) {
          ...GatsbyContentfulFixed
        }
      }
      esrbRating {
        fluid(maxWidth: 300, quality: 80,) {
          ...GatsbyContentfulFluid
        }
        fixed(width: 1920, quality: 80) {
          ...GatsbyContentfulFixed
        }
      }
      screenshots {
        description
        fluid(maxWidth: 300, quality: 80,) {
          ...GatsbyContentfulFluid
        }
        fixed(width: 1920, quality: 80) {
          ...GatsbyContentfulFixed
        }
      }
    }
    allContentfulBlogPost(limit: 1, sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate
        }
      }
    }
    allContentfulPressBlurbs(filter: {game: {slug: {eq: $slug }}}) {
      edges {
        node {
          excerpt
          score
          url
          publication
        }
      }
    }
    allContentfulYoutube(filter: {game: {slug: {eq: $slug }}}) {
      edges {
        node {
          title
          url
        }
      }
    }
    allContentfulStoreItems(sort: { fields: [sortPriority], order: DESC }) {
      edges {
        node {
          name
          url
          imageUrl
        }
      }
    }
  }
`
